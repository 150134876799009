@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.h50vh {
  height: 50vh;
}
.min-h-4rem {
  min-height: 4rem;
}
.li-finder > li > span {
  padding: 0 2rem 0 2rem;
  text-align: center;
  width: 10rem;
  max-width: 25vw;
  place-content: center;
}
/* .li-finder > li > ul {
  max-width: 25vw;
} */

.li-finder > li > ul > li > a {
  place-content: center;
}

.footer {
  display: grid;
  width: 100%;
  grid-auto-flow: column !important;
  place-items: start;
  row-gap: 2.5rem;
  column-gap: 1rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  justify-content: space-between;
  align-content: center;
  justify-items: center;
  /* position: fixed;
  bottom: 0; */
}
.card-body {
  max-height: 20vh;
  /* padding: 1rem; */
}

.mainCardsConteiner {
  /* width: 100vw; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  /* align-content: center; */
  padding: 2rem 0 2rem 0;
  height: auto;
}
.card-actions {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 0.5rem;
  flex-direction: row-reverse;
  justify-content: center;
  align-content: center;
  transform: scale(0.8);
}
#root
  > div
  > div.w-full.flex.justify-center.mt-8.mb-8
  > table
  > tbody
  > tr:last-child {
  border-radius: 0 !important;
  border-bottom: 0.25rem #1f242d solid;
}
#card {
  width: 20rem;
  height: 30rem;
  margin-bottom: 2rem;
  box-shadow: 3px 3px 3px 0px rgba(0, 0, 0);
  overflow: hidden;
  margin: 2rem;
}
#card > img {
  height: 100%;
  width: 100%;
  z-index: 0;
}
#card > img:hover ~ .move {
  transform: translateY(-30rem);
  transition: 0.5s;
}
hr {
  color: white;
  fill: white;
  background-color: white;
  margin: 1rem 0 1rem 0;
}
.move:hover {
  transition: 0.5s;
  transform: translateY(-30rem);
}

.move {
  color: white;
  background-color: #000000aa;
  width: 20rem;
  height: 30rem;
  padding: 2rem;
  transition: 0.5s;
}

.mainCardNoLogin {
  height: calc(50vh - 4rem);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10% 0 10%;
}

.mainCardNoLogin:first-child {
  max-width: 80vw;
}

.searchList {
  min-height: calc(100vh - 4rem);
}

.w80vw {
  width: 80vw;
}
.w50vw {
  width: 50vw;
}
.tran-75vw {
  transform: translateX(-75vw);
}
.tran-50vw {
  transform: translateX(-50vw);
}
.tran-25vw {
  transform: translateX(-25vw);
}

.ulDonwBarMenu {
  max-height: 40vh;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: center;
  width: 33vw;
}
